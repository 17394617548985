function Header() {
    const numbers = [1,2,3,4,5];
    return (
        <>
            <h1>Header</h1>
            <hr />

        </>
        
    )
}

export default Header;