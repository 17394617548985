import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import NoPage from '../pages/NoPage';

const AppRoutes = () => {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='*' element={<NoPage />} />
        </Routes>
    )
}

export default AppRoutes;