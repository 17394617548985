import Header from "../components/Header";

function NoPage() {
    return (
        <>
            <Header />
            <h2>Error: 404. Page not found</h2>
        </>
    )
}

export default NoPage;