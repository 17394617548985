import Header from "../components/Header";

const Home = () => {
    return (
        <>
            <Header />
            <div class="content top">
                <section class="contentWrap hero">
                    <h1>Hello, my name is Alfonse Surigao</h1>
                    <p>Lead Web Developer and former UX/UI Designer from Dallas, TX</p>

                    <div class="scrolldown"><i class="fa fa-chevron-down"></i></div>
                </section>
            </div>

            <div class="content intro">
                <section class="contentWrap introWrap">
                    <h2>I build websites, web-apps, and help design and create the best digital user experiences.</h2>

                </section>
            </div>

            <div class="projects">
                <section class="contentWrap">
                    <h1>Work</h1>


                </section>
            </div>
        </>
    )
}

export default Home;